import reducer from '@core-ui/reducers_handlers';
import { handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import * as actions from './actions';

export interface IBudgetHeaderState {
  loading: boolean;
}

const defaultState = Immutable<IBudgetHeaderState>({
  loading: false,
});

export default handleActions<typeof defaultState, any>(
  {},
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
