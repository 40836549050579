import { extractFlattenPaths, Route } from '@core-ui/redux-router';
import { ROUTES } from 'src/app/consts/routes';
import State from 'src/app/types/state';
import { resetBudgetHistory } from 'src/pages/finances/budget/BudgetHistoryDrawer/actions';
import { FINANCES_PATH_TABS } from 'src/pages/finances/consts';
import { resetBudgetFilters, setBudgetFiltersFromQuery, updateBudgetFiltersQuery } from './budget/BudgetFilter/actions';
import { getBudget, resetBudgetList } from './budget/BudgetRoot/actions';
import FinancesRoot from './FinancesRoot';

const key: keyof State = 'finances';

const routes: Route[] = [
  {
    key,
    path: `/:boatId([0-9]+)/:route(${ROUTES.FINANCES})/:financeTab(${FINANCES_PATH_TABS})`,
    exact: true,
    component: FinancesRoot,
    effects: [
      { events: ['on_enter'], trigger: resetBudgetList },
      { events: ['on_enter'], trigger: resetBudgetFilters },
      { events: ['on_enter'], trigger: resetBudgetHistory },
      { events: ['on_enter'], trigger: setBudgetFiltersFromQuery },
      { events: ['on_enter'], trigger: updateBudgetFiltersQuery },
      { events: ['on_enter'], trigger: getBudget },
    ],
  },
];

export const FINANCES_PATHS = extractFlattenPaths(routes);

export default routes;
