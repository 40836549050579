import { IBudgetForm } from 'src/pages/finances/types';
import { Decorator } from 'final-form';
import createDecorator from 'final-form-calculate';

export const totalCalculator = createDecorator({
  field: /rows\[(\d+)]\.month_values\[(\d+)]\.budget$/,
  updates: (_value, name, values) => {
    const totalFieldName = name.replace(/month_values\[\d+].budget/, 'total');
    const idx = name.match(/\d+/)![0];
    const allValues = values as IBudgetForm;
    const total =
      // @ts-ignore
      allValues.rows[idx]?.month_values.reduce<number>((acc, monthValue) => {
        acc += +monthValue.budget;

        return acc;
      }, 0) || 0;

    return { [totalFieldName]: total };
  },
});

export default [totalCalculator] as Decorator<IBudgetForm>[];
