import { useParams } from '@core-ui/redux-router';
import { FINANCES_TABS } from 'src/pages/finances/consts';
import { FINANCES_PATHS } from 'src/pages/finances/routes';
import { FinancesPathParams } from 'src/pages/finances/types';
import { FC } from 'react';
import BudgetRoot from '../budget/BudgetRoot';

const FinancesList: FC = () => {
  const { financeTab } = useParams<FinancesPathParams>(FINANCES_PATHS);

  return <>{financeTab === FINANCES_TABS.BUDGET && <BudgetRoot />}</>;
};

export default FinancesList;
