import {
  getBudgetApproveFlowEnabled,
  getCostArticleMappingEnabled,
  getUserCostArticlesMappingLoading,
} from 'src/app/selectors';
import State from 'src/app/types/state';
import { createSelector } from 'reselect';

const getHeaderLoading = (state: State) => state.finances.budgetsHeader.loading;

export default createSelector(
  [getHeaderLoading, getUserCostArticlesMappingLoading, getCostArticleMappingEnabled, getBudgetApproveFlowEnabled],
  (headerLoading, userCostArticlesMappingLoading, costArticlesMappingEnabled, budgetApproveFlowActive) => ({
    loading: headerLoading || userCostArticlesMappingLoading,
    costArticlesMappingEnabled,
    budgetApproveFlowActive,
  })
);
