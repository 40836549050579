import State from 'src/app/types/state';
import { getAmountFormatter } from 'src/dictionary/selector';
import { createSelector } from 'reselect';

export const getBudgetForm = (state: State) => state.finances.budgets.budgetForm!;

export default createSelector([getBudgetForm, getAmountFormatter], (budgetForm, amountFormatter) => ({
  budgetForm,
  amountFormatter,
}));
