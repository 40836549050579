import reducer, { List } from '@core-ui/reducers_handlers';
import { BudgetHistorySchema } from 'src/generated';
import { Action, handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import * as actions from './actions';

export interface IBudgetHistoryDrawerList extends List<BudgetHistorySchema> {
  paginationPage: number;
  isOpened: boolean;
}

const defaultState = Immutable<IBudgetHistoryDrawerList>({
  loaded: false,
  hasData: false,
  loading: false,
  chunkLoading: false,
  isAllDataReceived: false,
  paginationPage: 0,
  isOpened: false,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setPaginationPage.toString()]: (state, { payload }: Action<number>) => {
      return state.set('paginationPage', payload);
    },
    [actions.setDrawerOpened.toString()]: (state, { payload }: Action<boolean>) => {
      return state.set('isOpened', payload);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
