import { COLOURS } from '@core-ui/styles';
import { Nullable } from '@core-ui/types';
import { Action } from 'redux-actions';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { GET, PUT } from 'src/api/oceanApi';
import { setCurrentVehicleConfig, toggleUserCostArticlesMapping } from 'src/app/actions';
import { responseError } from 'src/app/sagas';
import { getCostArticleMappingEnabled, vehicleIdSelector } from 'src/app/selectors';
import { endOfYear, convertValueToUTCDate, getTimestampInMs, startOfYear, dateToUTC } from 'src/app/utils/dates';
import { showNotification } from 'src/components/Notification/actions';
import {
  Budget,
  BudgetInputSchema,
  CAFlatResponseSchema,
  ClientCostArticleBudget,
  ReadBudgetOutputSchema,
  ReadClientArticlesBudgets,
  TotalBudget,
} from 'src/generated';
import { resetBudgetHistory } from 'src/pages/finances/budget/BudgetHistoryDrawer/actions';
import { currencyId } from '../../consts';
import { IBudget, IBudgetForm, IBudgetRow } from '../../types';
import { IBudgetFiltersState } from '../BudgetFilter/reducer';
import { getFiltersSelector } from '../BudgetFilter/selector';
import { BUDGET_FORM } from '../BudgetTable/BudgetTable';
import { IInterval, makeInterval } from '../BudgetTable/utils';
import * as actions from './actions';

const createSourceCostArticlesBudgetTableRows = (
  costArticleSections: CAFlatResponseSchema[],
  headers: IInterval[],
  defaultColor: string
): IBudgetRow[] =>
  // each cost article section contains an uneditable root row and editable children rows
  costArticleSections.reduce<IBudgetRow[]>((tableRows, costArticleSection) => {
    const sectionRows: IBudgetRow[] = [];
    const childrenRows: IBudgetRow[] = [];

    // create children rows for a section
    if (costArticleSection.children.length) {
      costArticleSection.children.forEach((childRow) => {
        const monthCells: Budget[] = [];
        let rowTotalBudget = 0;

        // create month cells and calculate a row's total for a child row
        headers.forEach((interval) => {
          const foundBudget = childRow?.budgets?.find(
            (budget) => budget.month === interval.month && budget.year === interval.year
          );

          rowTotalBudget += foundBudget?.budget ? foundBudget.budget : 0;

          monthCells.push({
            cost_article_id: childRow.id,
            budget: foundBudget?.budget ?? undefined,
            month: foundBudget?.month ?? interval.month,
            year: foundBudget?.year ?? interval.year,
            currency_id: foundBudget?.currency_id ?? -1,
            id: foundBudget?.id ?? -1,
          });
        });

        const tableChildRow: IBudgetRow = {
          cost_article_id: childRow.id,
          cost_article_name: childRow.name,
          currency_id: childRow.currency_id ?? 103,
          color: costArticleSection.color ?? defaultColor,
          month_values: monthCells,
          total: rowTotalBudget,
          isRootRow: false,
        };

        childrenRows.push(tableChildRow);
      });
    }

    // calculate total budget of the root row (sum of all children rows total budgets)
    const sectionRootBudgetTotal = childrenRows.reduce<number>((rootTotalBudget, currentChildRow) => {
      return rootTotalBudget + currentChildRow.total;
    }, 0);

    // calculate month values for the root row
    const sectionRootMonthValues = childrenRows.reduce<Budget[]>((rootMonthValues, currentChildRow) => {
      // first iteration
      if (!rootMonthValues.length) {
        return currentChildRow.month_values;
      }

      return rootMonthValues.map((rootMonthValue, index) => {
        let budget: number | undefined;

        if (currentChildRow.month_values[index].budget) {
          if (rootMonthValue.budget) {
            budget = rootMonthValue.budget + (currentChildRow.month_values[index].budget ?? 0);
          } else {
            budget = currentChildRow.month_values[index].budget;
          }
        } else if (rootMonthValue.budget) {
          budget = rootMonthValue.budget;
        }

        return {
          ...rootMonthValue,
          budget,
        };
      });
    }, []);

    // create root row and push it to the result array
    // we push it before children rows because we want to display root rows above children rows
    sectionRows.push({
      cost_article_id: costArticleSection.id,
      cost_article_name: costArticleSection.name,
      currency_id: costArticleSection.currency_id ? costArticleSection.currency_id : 103,
      color: costArticleSection.color ?? defaultColor,
      total: sectionRootBudgetTotal,
      month_values: sectionRootMonthValues,
      isRootRow: true,
    });

    sectionRows.push(...childrenRows);

    return [...tableRows, ...sectionRows];
  }, []);

const createClientCostArticlesBudgetTableRows = (
  costArticles: ClientCostArticleBudget[],
  headers: IInterval[],
  defaultColor: string
): IBudgetRow[] =>
  // client defined cost articles are not grouped by sections thus they don't have a root row and all rows are editable
  costArticles.map((costArticle) => {
    const monthCells: IBudget[] = [];
    let rowTotalBudget = 0;

    // create month cells for this cost article row
    headers.forEach((interval) => {
      const foundBudget = costArticle?.budgets?.find(
        (budget) => budget.month === interval.month && budget.year === interval.year
      );

      rowTotalBudget += foundBudget?.budget ? foundBudget.budget : 0;

      monthCells.push({
        cost_article_id: costArticle.client_cost_article_id,
        client_cost_article_id: costArticle.client_cost_article_id,
        cost_article_clients_name: costArticle.cost_article_clients_name,
        cost_article_clients_code: costArticle.cost_article_clients_code,
        budget: foundBudget?.budget ?? undefined,
        month: foundBudget?.month ?? interval.month,
        year: foundBudget?.year ?? interval.year,
        currency_id: foundBudget?.currency_id ?? -1,
        id: foundBudget?.id ?? -1,
      });
    });

    const tableRow: IBudgetRow = {
      cost_article_id: costArticle.client_cost_article_id,
      cost_article_name: costArticle.cost_article_clients_name,
      currency_id: costArticle.currency_id ?? 103,
      color: costArticle.color ?? defaultColor,
      month_values: monthCells,
      total: rowTotalBudget,
      // user defined cost articles don't have root cost articles
      isRootRow: false,
    };

    return tableRow;
  });

function* getBudget() {
  try {
    const boatId: Nullable<number> = yield select(vehicleIdSelector);
    const { dateFrom, dateTo }: IBudgetFiltersState = yield select(getFiltersSelector);
    const costArticleMappingEnabled: boolean = yield select(getCostArticleMappingEnabled);
    const date = dateToUTC(new Date()).getTime();

    if (BUDGET_FORM) {
      yield put(actions.setBudgetForm(null));
    }

    // we have different endpoints for our cost articles and cost articles provided by the client
    const path = costArticleMappingEnabled ? `/budgets/${boatId}/client_cost_articles` : `/budgets/${boatId}`;

    const data: ReadBudgetOutputSchema | ReadClientArticlesBudgets = yield call(GET, path, {
      date_from: dateFrom ? Number(dateFrom) : dateToUTC(startOfYear(date)).getTime(),
      date_to: dateTo ? Number(dateTo) : dateToUTC(endOfYear(date)).getTime(),
    });

    const headers = makeInterval(dateFrom, dateTo);

    /*
     * старая логика расчёта тоталов (верхних границ расходов по месяцам) которые в данный момент не нужны но могут потребоваться в будущем
     */
    // const totalBudgets: TotalBudget[] = headers.map(
    //   (interval) =>
    //     data.total_budgets?.find((budget) => budget.month === interval.month && budget.year === interval.year) ?? {
    //       budget: undefined,
    //       cost_article_id: -1,
    //       month: interval.month,
    //       year: interval.year,
    //       currency_id: -1,
    //       id: -1,
    //     }
    // );

    const monthSubtotal = headers.map((interval) => {
      if (costArticleMappingEnabled) {
        // у клиентских артиклов полностью плоская структура, в их артиклах сразу лежит массив бюджетов по месяцам
        return (data as ReadClientArticlesBudgets).cost_articles?.reduce<number>((costArticleSubtotal, costArticle) => {
          const intervalArticleBudget = costArticle?.budgets?.find(
            (budget) => budget.month === interval.month && budget.year === interval.year
          );

          return costArticleSubtotal + (intervalArticleBudget?.budget ?? 0);
        }, 0);
      } else {
        // в наших артиклах нет бюджетов, у них есть дочерние артиклы, в которых лежат массивы бюджетов по месяцам
        return (data as ReadBudgetOutputSchema).cost_articles?.reduce<number>((costArticleSubtotal, costArticle) => {
          const childArticleBudget = costArticle?.children?.reduce<number>((childArticleSubtotal, child) => {
            const intervalChildBudget = child?.budgets?.find(
              (budget) => budget.month === interval.month && budget.year === interval.year
            );

            return childArticleSubtotal + (intervalChildBudget?.budget ?? 0);
          }, 0);

          return costArticleSubtotal + (childArticleBudget ?? 0);
        }, 0);
      }
    });
    const initialValues: IBudgetForm = {
      rows: costArticleMappingEnabled
        ? createClientCostArticlesBudgetTableRows(
            // в этом кейсе у cost_articles всегда будет тип ClientCostArticleBudget[], но TS этого не видит
            (data.cost_articles ?? []) as ClientCostArticleBudget[],
            headers,
            COLOURS.CLEAR
          )
        : createSourceCostArticlesBudgetTableRows(
            // в этом кейсе у cost_articles всегда будет тип CAFlatResponseSchema[], но TS этого не видит
            (data.cost_articles ?? []) as CAFlatResponseSchema[],
            headers,
            COLOURS.CLEAR
          ),
      monthSubtotal,
    };

    if (!initialValues.rows || initialValues.rows.length === 0) {
      throw new Error('No cost article rows found');
    }

    if (!initialValues.monthSubtotal || initialValues.monthSubtotal.length === 0) {
      throw new Error('No total budgets found');
    }

    if (BUDGET_FORM) {
      BUDGET_FORM.setConfig('keepDirtyOnReinitialize', false);
      BUDGET_FORM.initialize(initialValues);
      BUDGET_FORM.setConfig('keepDirtyOnReinitialize', true);
    }

    yield all([
      put(actions.setBudgetForm(initialValues)),
      put(
        actions.setBudget({
          value: data,
          hasData: Boolean(data),
        })
      ),
      put(actions.setHeadersList(headers)),
    ]);
  } catch (e) {
    yield call(responseError, e);
    yield put(actions.setBudget({ error: e as Error, hasData: false }));
  }
}

function* saveBudget({ payload }: Action<IBudget>) {
  try {
    yield put(
      showNotification({
        variant: 'info',
        titleId: 'notification.info.text.applying_changes',
      })
    );

    const costArticleMappingEnabled: boolean = yield select(getCostArticleMappingEnabled);
    const boatId: Nullable<number> = yield select(vehicleIdSelector);

    yield call(PUT, `/budgets/${boatId}`, {
      cost_article_id: costArticleMappingEnabled ? undefined : payload.cost_article_id,
      client_cost_article_id: costArticleMappingEnabled ? payload.client_cost_article_id : undefined,
      cost_article_budget: payload.budget ? Number(payload.budget) : 0,
      currency_id: currencyId,
      year: payload.year,
      month: payload.month,
    } as BudgetInputSchema);

    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.budget_changed',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    // TODO: заменить полный рефетч динамическим изменением значений в суммах по столбцу
    yield all([put(actions.getBudget(null)), put(resetBudgetHistory())]);
  }
}

// TODO: этот экшен и сага не используются, но пока что не удаляем т.к. могут потребоваться
function* saveTotalMonthBudget({ payload }: Action<TotalBudget>) {
  try {
    yield put(
      showNotification({
        variant: 'info',
        titleId: 'notification.info.text.applying_changes',
      })
    );

    const boatId: Nullable<number> = yield select(vehicleIdSelector);

    yield call(PUT, `/budgets/${boatId}`, {
      total_budget_value: payload.budget ? Number(payload.budget) : 0,
      currency_id: currencyId,
      year: payload.year,
      month: payload.month,
    } as BudgetInputSchema);

    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.budget_changed',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    // TODO: заменить полный рефетч динамическим изменением значений в суммах по столбцу
    yield all([put(actions.getBudget(null)), put(resetBudgetHistory())]);
  }
}

function* updateCostArticlesMappingFromBudgets() {
  try {
    yield put(toggleUserCostArticlesMapping());
    yield take(setCurrentVehicleConfig);

    yield all([put(resetBudgetHistory()), put(actions.resetBudgetList())]);
    yield put(actions.getBudget(null));
  } catch (e) {
    yield call(responseError, e);
  }
}

export default [
  takeLatest(actions.getBudget, getBudget),
  takeLatest(actions.saveBudget, saveBudget),
  takeLatest(actions.saveTotalMonthBudget, saveTotalMonthBudget),
  takeLatest(actions.updateCostArticlesMappingFromBudgets, updateCostArticlesMappingFromBudgets),
];
