import { Nullable } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import { GET } from 'src/api/oceanApi';
import { vehicleIdSelector } from 'src/app/selectors';
import { showNotification } from 'src/components/Notification/actions';
import { BudgetHistorySchema } from 'src/generated';
import { getPaginationPage } from 'src/pages/finances/budget/BudgetHistoryDrawer/selector';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

const LIMIT = 50;

interface IFetchHistoryItems {
  page: number;
}

function* fetchHistoryItems({ page }: IFetchHistoryItems) {
  const boatId: Nullable<number> = yield select(vehicleIdSelector);

  const requestUrl = buildURL(`/budgets/${boatId}/history`, {
    page,
    per_page: LIMIT,
  });

  const items: BudgetHistorySchema[] = yield call(GET, requestUrl);

  return items;
}

function* getBudgetHistory() {
  try {
    const items: BudgetHistorySchema[] = yield fetchHistoryItems({ page: 0 });

    yield all([
      put(actions.setPaginationPage(0)),
      put(
        actions.setBudgetHistory({
          value: items,
          hasData: Boolean(items.length),
          isAllDataReceived: items.length < LIMIT,
        })
      ),
    ]);
  } catch (e) {
    yield all([
      put(
        showNotification({
          variant: 'error',
          titleId: 'notification.error.text.get_budget_activity_feed',
        })
      ),
      put(
        actions.setBudgetHistory({
          error: e as Error,
          hasData: false,
          isAllDataReceived: false,
        })
      ),
    ]);
  }
}

function* getChunkBudgetHistory() {
  try {
    const page: number = yield select(getPaginationPage);
    const newItems: BudgetHistorySchema[] = yield fetchHistoryItems({ page: page + 1 });

    yield all([
      put(actions.setPaginationPage(page + 1)),
      put(
        actions.setChunkBudgetHistory({
          value: newItems,
          isAllDataReceived: newItems.length < LIMIT,
        })
      ),
    ]);
  } catch (e) {
    yield put(
      showNotification({
        variant: 'error',
        titleId: 'notification.error.text.get_budget_activity_feed',
      })
    );
  }
}

export default [
  takeLatest(actions.getBudgetHistory, getBudgetHistory),
  takeLatest(actions.getChunkBudgetHistory, getChunkBudgetHistory),
];
