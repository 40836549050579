import { createParseQuery, resetState, setFieldFilter } from '@core-ui/reducers_handlers';
import { createAction } from 'redux-actions';

export const reducerName = 'budget.filters';

export const setTextFilter = setFieldFilter(reducerName);
export const resetBudgetFilters = resetState(reducerName);

export const updateBudgetFiltersQuery = createAction(`${reducerName}.UPDATE_BUDGET_FILTERS_QUERY`);
export const setBudgetFiltersFromQuery = createParseQuery(reducerName);
