import { HEIGHT_SIZE } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { BUDGET_FILTERS_HEIGHT, BUDGET_HEADER_HEIGHT } from 'src/pages/finances/consts';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    padding: `${HEIGHT_SIZE.tabsHeight}px 24px 0 !important`,
    height: `calc(100vh - (${HEIGHT_SIZE.headerHeight}px))`,
    overflow: 'hidden',
  },

  noDataContainer: {
    flexWrap: 'nowrap',
    height: `calc(100vh - (${
      HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight + BUDGET_HEADER_HEIGHT + BUDGET_FILTERS_HEIGHT
    }px))`,
    overflow: 'hidden',
  },
});
