import { COLOURS } from '@core-ui/styles';
import Switch from '@core-ui/switch';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Info } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { TRANSITION_TIMING_FUNCTION } from 'src/app/consts/app';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { updateCostArticlesMappingFromBudgets } from 'src/pages/finances/budget/BudgetRoot/actions';
import { toggleBudgetApproveFlow } from './actions';
import selector from './selector';

const switchContainerStyles: SxProps<Theme> = {
  color: COLOURS.HUE_COCONUT.HUE_153,
  cursor: 'pointer',
  transition: `all 250ms ${TRANSITION_TIMING_FUNCTION}`,

  '&:hover': {
    color: COLOURS.HUE_COCONUT.HUE_195,
  },
};

const BudgetHeader: FC = () => {
  const { loading, costArticlesMappingEnabled, budgetApproveFlowActive } = useAppSelector(selector);
  const dispatch = useDispatch();

  const handleApprovalFlowChange = () => {
    dispatch(toggleBudgetApproveFlow());
  };

  const handleCostArticlesMappingChange = () => {
    dispatch(updateCostArticlesMappingFromBudgets());
  };

  return (
    <Grid container justifyContent="flex-end" paddingTop={6}>
      {/* todo: добавить эти два числа когда бэк сделает ручку */}
      {/* <Grid item container width="auto" gap={20}> */}
      {/*   <Grid item container width="auto" direction="column" gap={1}> */}
      {/*     <Typography color="text.secondary" component="div"> */}
      {/*       <FormattedMessage id="label.ytd_budget" /> */}
      {/*     </Typography> */}

      {/*     <Typography component="div" fontSize="larger" fontWeight="semiBold"> */}
      {/*       /!* <Amount amount={} currencyId={} /> *!/ */}
      {/*       123,456.78 */}
      {/*     </Typography> */}
      {/*   </Grid> */}

      {/*   <Grid item container width="auto" direction="column" gap={1}> */}
      {/*     <Typography color="text.secondary" component="div"> */}
      {/*       <FormattedMessage id="label.ytd_expenses" /> */}
      {/*     </Typography> */}

      {/*     <Typography component="div" fontSize="larger" fontWeight="semiBold"> */}
      {/*       /!* <Amount amount={} currencyId={} /> *!/ */}
      {/*       123,456.78 */}
      {/*     </Typography> */}
      {/*   </Grid> */}
      {/* </Grid> */}

      <Grid item container width="auto" gap={9}>
        <Grid item container width="auto" gap={2}>
          <Grid item>
            <Tooltip title={<FormattedMessage id="tooltip.budget_cost_article_mapping_switch" />} placement="top" arrow>
              <SvgIcon htmlColor={COLOURS.HUE_COCONUT.HUE_128}>
                <Info />
              </SvgIcon>
            </Tooltip>
          </Grid>

          <Grid
            item
            container
            gap={3}
            width="auto"
            sx={switchContainerStyles}
            onClick={handleCostArticlesMappingChange}
          >
            <Typography>
              <FormattedMessage id="budget.use_my_categories" />
            </Typography>

            <Grid item>
              <Switch size="large" disabled={loading} checked={costArticlesMappingEnabled} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container width="auto" gap={2}>
          <Grid item>
            <Tooltip title={<FormattedMessage id="tooltip.budget_document_approve_switch" />} placement="top" arrow>
              <SvgIcon htmlColor={COLOURS.HUE_COCONUT.HUE_128}>
                <Info />
              </SvgIcon>
            </Tooltip>
          </Grid>

          <Grid
            item
            container
            gap={3}
            width="auto"
            sx={switchContainerStyles}
            onClick={handleApprovalFlowChange}
          >
            <Typography>
              <FormattedMessage id="label.budget_expenses" />
            </Typography>

            <Grid item>
              <Switch size="large" disabled={loading} checked={budgetApproveFlowActive} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BudgetHeader;
