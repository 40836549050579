import reducer, { Data } from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import { ReadBudgetOutputSchema } from 'src/generated';
import { IBudgetForm, IHeader } from '../../types';
import * as actions from './actions';

export interface IBudgetsState extends Data<ReadBudgetOutputSchema> {
  budgetForm: Nullable<IBudgetForm>;
  headers: IHeader[];
}

const defaultState = Immutable<IBudgetsState>({
  loaded: false,
  hasData: false,
  loading: false,
  budgetForm: null,
  headers: [],
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setBudgetForm.toString()]: (state, { payload }) => {
      return state.set('budgetForm', payload);
    },
    [actions.setHeadersList.toString()]: (state, { payload }) => {
      return state.set('headers', payload);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
