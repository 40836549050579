import { COLOURS, HEIGHT_SIZE } from '@core-ui/styles';
import { monthItemWidth } from 'src/pages/finances/consts';

const offset = 10;
const border = `1px solid ${COLOURS.BORDER.MAIN}`;

export default () => ({
  totalCell: {
    borderTop: border,
    borderBottom: `3px solid ${COLOURS.BORDER.MAIN}`,
    borderRight: border,
    height: HEIGHT_SIZE.headerHeight,
    backgroundColor: COLOURS.HUE_COCONUT.HUE_12,
    color: COLOURS.HUE_COCONUT.HUE_195,
  },
  totalActive: {
    position: 'absolute',
    top: 0,
    left: -offset / 2,
    zIndex: 1,

    '.MuiOutlinedInput-root': {
      width: monthItemWidth + offset,
      height: HEIGHT_SIZE.headerHeight,
    },
  },
});
