import { AmountFormatter } from 'src/app/types/common';
import React, { FC } from 'react';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { IBudgetRow } from 'src/pages/finances/types';
import BudgetRow from '../BudgetRow';

interface IProps extends FieldArrayRenderProps<IBudgetRow, any> {
  numberFormatter: AmountFormatter;
}

const BudgetRows: FC<IProps> = (props) => {
  const { fields, numberFormatter } = props;

  return (
    <div>
      {fields.map((rowPath, idx) => {
        const row = fields.value[idx];

        return (
          <BudgetRow
            key={rowPath}
            total={row.total}
            color={row.color}
            isRootRow={row.isRootRow}
            amountFormatter={numberFormatter}
            rowPath={rowPath}
            nameArticle={row.cost_article_name}
          />
        );
      })}
    </div>
  );
};

export default BudgetRows;
