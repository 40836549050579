import State from 'src/app/types/state';
import { createSelector } from 'reselect';
import { IBudgetFiltersState } from './reducer';

export const keys: Array<keyof IBudgetFiltersState> = ['dateFrom', 'dateTo'];

export const getFiltersSelector = (state: State) => state.finances.budgetsFilter;

const getLoading = (state: State) => state.finances.budgetsFilter.loading;

export default createSelector([getFiltersSelector, getLoading], (filters, loading) => ({
  filters,
  loading,
}));
