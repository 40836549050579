import { useTableDynamicShadows } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { useRef } from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { IBudgetForm } from '../../types';
import BudgetRows from './components/BudgetRows';
import BudgetHeader from './components/HeaderBudget';
import decorators from './decorators';
import selector from './selector';
import useStyles from './styles';

export let BUDGET_FORM: FormApi<IBudgetForm>;

const BudgetTable = () => {
  const { budgetForm, amountFormatter } = useAppSelector(selector);

  const styles = useStyles();
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const applyRef = useTableDynamicShadows(ref);

  const onSubmit = () => {
    // nothing
  };

  return (
    <Grid container sx={styles.container} ref={applyRef}>
      <Form<IBudgetForm>
        keepDirtyOnReinitialize
        initialValues={budgetForm}
        subscription={{}}
        decorators={decorators}
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, form }) => {
          BUDGET_FORM = form;

          return (
            <form onSubmit={handleSubmit}>
              <BudgetHeader amountFormatter={amountFormatter} />

              {/* @ts-ignore */}
              <FieldArray name="rows" component={BudgetRows} numberFormatter={amountFormatter} />
            </form>
          );
        }}
      />
    </Grid>
  );
};

export default BudgetTable;
