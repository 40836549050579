export const articlesContainerWidth = 298;
export const totalsContainerWidth = 99;
export const totalItemWidth = 144;
export const articleItemWidth = 292;
export const monthItemWidth = 120;
export const currencyId = 103;

export enum FINANCES_TABS {
  BUDGET = 'budget',
}

export const FINANCES_PATH_TABS = Object.values(FINANCES_TABS).join('|');

export const ENTER_KEY = 'Enter';

export const BUDGET_HEADER_HEIGHT = 48;
export const BUDGET_FILTERS_HEIGHT = 96;
