import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { DRAWER_GRID_ITEM_WIDTH, DRAWER_WIDTH } from 'src/pages/backoffice/Ledger/consts';

export default (): Record<string, SxProps<Theme>> => ({
  drawer: {
    width: DRAWER_WIDTH,
    backgroundColor: COLOURS.HUE_COCONUT.HUE_16,
  },
  closeButton: {
    backgroundColor: `${COLOURS.HUE_COCONUT.HUE_8} !important`,
    borderColor: 'transparent !important',

    '&:hover': {
      borderColor: `${COLOURS.HUE_COCONUT.HUE_100} !important`,
    },
  },
  gridItem: {
    maxWidth: `${DRAWER_GRID_ITEM_WIDTH}px !important`,
  },
});
