import State from 'src/app/types/state';
import { NoDataContainerSelector } from 'src/components/NoDataContainer/NoDataContainer';
import { createSelector } from 'reselect';

const getChunkLoading = (state: State) => state.finances.budgetHistoryDrawer.chunkLoading;
const getAllDataReceived = (state: State) => state.finances.budgetHistoryDrawer.isAllDataReceived;

export const chunkLoaderSelector = createSelector(
  [getChunkLoading, getAllDataReceived],
  (isChunkLoading, isAllDataReceived) => ({
    isChunkLoading,
    isAllDataReceived,
  })
);

const getBudgetHistory = (state: State) => state.finances.budgetHistoryDrawer;

export const getNoDataContainerData: NoDataContainerSelector = createSelector([getBudgetHistory], (state) => ({
  loading: state.loading,
  loaded: state.loaded,
  hasData: state.hasData,
  error: state.error,
}));

export const getPaginationPage = (state: State) => state.finances.budgetHistoryDrawer.paginationPage;

export default createSelector([getBudgetHistory], (budgetHistory) => ({
  items: budgetHistory.value,
  isOpened: budgetHistory.isOpened,
}));
