import { COLOURS, HEIGHT_SIZE } from '@core-ui/styles';
import { DYNAMIC_BOX_SHADOW } from '@core-ui/table';
import { SxProps, Theme } from '@mui/material/styles';
import { articleItemWidth, articlesContainerWidth, totalItemWidth } from 'src/pages/finances/consts';

const border = `1px solid ${COLOURS.BORDER.MAIN}`;

export default (props: { color: string; isRootRow: boolean }): Record<string, SxProps<Theme>> => ({
  costArticleContainer: {
    flex: `0 0 ${articlesContainerWidth}px`,
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: COLOURS.HUE_COCONUT.HUE_12,

    ...DYNAMIC_BOX_SHADOW.dynamicRight,
  },

  costArticleColor: {
    borderBottom: border,
    borderRight: border,
    borderLeft: border,
    width: 6,
    backgroundColor: props.color,
    height: HEIGHT_SIZE.headerHeight,
  },

  costArticleName: {
    borderBottom: border,
    borderRight: border,
    width: articleItemWidth,
    height: HEIGHT_SIZE.headerHeight,
  },

  total: {
    borderBottom: border,
    borderRight: border,
    height: HEIGHT_SIZE.headerHeight,
    width: totalItemWidth,
    flex: `1 0 ${totalItemWidth}px`,
    position: 'sticky',
    zIndex: 1,
    right: 0,
    backgroundColor: COLOURS.HUE_COCONUT.HUE_12,

    ...DYNAMIC_BOX_SHADOW.dynamicLeft,
  },
});
