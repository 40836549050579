import State from 'src/app/types/state';
import { NoDataContainerSelector } from 'src/components/NoDataContainer/NoDataContainer';
import { createSelector } from 'reselect';

const getOceanCostArticlesEditDisabled = (state: State) => state.finances.budgets.value?.is_disabled_for_editing;

export const getCellsDisabled = () =>
  createSelector([getOceanCostArticlesEditDisabled], (oceanCostArticlesEditDisabled) => ({
    oceanCostArticlesEditDisabled,
  }));

const getBudgets = (state: State) => state.finances.budgets;

export const noDataSelector: NoDataContainerSelector = createSelector([getBudgets], (state) => ({
  loading: state.loading,
  loaded: state.loaded,
  hasData: state.hasData,
  error: state.error,
}));
